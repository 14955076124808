//@ts-nocheck

import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Card,
  FormControl,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio, Box, ListItemText, ListItem, List,
} from '@mui/material'
import './App.css'

const quizDataEasy = [
  {
    "question": "Какая молекулярная техника используется для определения точной последовательности антигенов HLA у донора и реципиента?",
    "options": [
      "Гель-электрофорез",
      "ПЦР-амплификация",
      "Секвенирование следующего поколения (NGS)",
      "Флуоресцентная ин ситу гибридизация (FISH)"
    ],
    "answer": "Секвенирование следующего поколения (NGS)"
  },
  {
    "question": "Какой иммунологический феномен может привести к развитию толерантности реципиента к трансплантированному органу без необходимости в длительной иммуносупрессии?",
    "options": [
      "Химеризм",
      "Анергия",
      "Клональное исчерпание",
      "Иммунная привилегия"
    ],
    "answer": "Химеризм"
  },
  {
    "question": "Какой метод используется для оценки минимального остаточного болезненного состояния после трансплантации гемопоэтических стволовых клеток при лечении лейкемии?",
    "options": [
      "Флоу-цитометрия",
      "Полимеразная цепная реакция (ПЦР)",
      "Биопсия костного мозга",
      "Иммуногистохимия"
    ],
    "answer": "Флоу-цитометрия"
  },
  {
    "question": "Какой биологический процесс лежит в основе разработки индивидуализированных иммуносупрессивных стратегий для предотвращения отторжения трансплантатов?",
    "options": [
      "Генетическая рекомбинация",
      "Иммунная толерантность",
      "Фармакогеномика",
      "Апоптоз"
    ],
    "answer": "Фармакогеномика"
  },
  {
    "question": "Какой вид донорства включает донорство органов или тканей после смерти мозга, но при поддержании сердечно-сосудистой системы?",
    "options": [
      "Живое донорство",
      "Посмертное донорство",
      "Донорство с сердечным смертью",
      "Донорство с мозговой смертью"
    ],
    "answer": "Донорство с мозговой смертью"
  },
  {
    "question": "Какой метод используется для оценки перфузии и функции органа перед трансплантацией?",
    "options": [
      "МРТ",
      "Эхокардиография",
      "Экз виво оценка органа",
      "Биопсия"
    ],
    "answer": "Экз виво оценка органа"
  },
  {
    "question": "Какой иммунологический тест определяет наличие антител у реципиента, которые могут атаковать трансплантат?",
    "options": [
      "Кросс-матч тест",
      "Тест на группу крови",
      "Генетический тест",
      "Иммунофлуоресценция"
    ],
    "answer": "Кросс-матч тест"
  },
  {
    "question": "Как называется процедура, при которой пациент получает стволовые клетки для восстановления костного мозга?",
    "options": [
      "Гемодиализ",
      "Гемопоэтическая стволовая клеточная трансплантация",
      "Лимфодренаж",
      "Миелоабляция"
    ],
    "answer": "Гемопоэтическая стволовая клеточная трансплантация"
  },
  {
    "question": "Какой протокол трансплантации используется для минимизации риска отторжения при трансплантации между неидентичными близкими родственниками?",
    "options": [
      "HLA-матчинг",
      "ABO-несовместимая трансплантация",
      "Мини-трансплантация",
      "Двойная трансплантация"
    ],
    "answer": "ABO-несовместимая трансплантация"
  },
  {
    "question": "Какой вид биопсии чаще всего используется для мониторинга состояния трансплантированной почки?",
    "options": [
      "Трансюгулярная биопсия",
      "Перкутанная биопсия",
      "Эндоскопическая биопсия",
      "Лапароскопическая биопсия"
    ],
    "answer": "Перкутанная биопсия"
  },
]
const quizDataMedium = [

  {
    "question": "Как называется патологический процесс, при котором трансплантированный орган повреждается иммунной системой реципиента?",
    "options": [
      "Хроническое отторжение",
      "Акутное отторжение",
      "Гиперактивная иммунная реакция",
      "Иммунная недостаточность"
    ],
    "answer": "Хроническое отторжение"
  },
  {
    "question": "Какой тип лекарственного препарата обычно используется для поддержания уровня иммуносупрессии у пациентов после трансплантации?",
    "options": [
      "Антибиотики",
      "Кортикостероиды",
      "Иммуномодуляторы",
      "Кальциневриновые ингибиторы"
    ],
    "answer": "Кальциневриновые ингибиторы"
  },
  {
    "question": "Какой медицинский термин описывает ситуацию, когда трансплантированный орган начинает атаковать организм реципиента?",
    "options": [
      "Графт-против-хозяина болезнь",
      "Хозяин-против-графта реакция",
      "Иммунная толерантность",
      "Аутоиммунная реакция"
    ],
    "answer": "Графт-против-хозяина болезнь"
  },
  {
    "question": "Какое медицинское состояние может быть следствием длительного использования иммуносупрессивных препаратов?",
    "options": [
      "Диабет",
      "Остеопороз",
      "Гипертония",
      "Лимфома"
    ],
    "answer": "Лимфома"
  },
  {
    "question": "Какой генетический тест используется для оценки совместимости донора и реципиента при трансплантации костного мозга?",
    "options": [
      "Кариотипирование",
      "Полимеразная цепная реакция (ПЦР)",
      "Геномное секвенирование",
      "HLA-типирование"
    ],
    "answer": "HLA-типирование"
  },
  {
    "question": "Какой метод используется для уменьшения иммунной реактивности реципиента перед трансплантацией костного мозга?",
    "options": [
      "Иммуносупрессия",
      "Радиационная терапия",
      "Химиотерапия",
      "Кондиционирование"
    ],
    "answer": "Кондиционирование"
  },
  {
    "question": "Какой орган чаще всего подвергается трансплантации?",
    "options": [
      "Почка",
      "Сердце",
      "Печень",
      "Легкое"
    ],
    "answer": "Почка"
  },
  {
    "question": "Какой метод используется для снижения риска отторжения трансплантата?",
    "options": [
      "Хирургическое вмешательство",
      "Применение иммуносупрессивных препаратов",
      "Физиотерапевтическое лечение",
      "Психотерапия"
    ],
    "answer": "Применение иммуносупрессивных препаратов"
  },
  {
    "question": "Как называется процесс замены повреждённого органа органом от донора?",
    "options": [
      "Гемотрансфузия",
      "Органная трансплантация",
      "Биопсия",
      "Реанимация"
    ],
    "answer": "Органная трансплантация"
  },
  {
    "question": "Каков основной риск после трансплантации органа?",
    "options": [
      "Инфекция",
      "Отторжение трансплантата",
      "Хроническая усталость",
      "Аллергическая реакция"
    ],
    "answer": "Отторжение трансплантата"
  },
  {
    "question": "Что такое аутотрансплантация?",
    "options": [
      "Пересадка органа от одного человека другому",
      "Пересадка органа от животного человеку",
      "Пересадка тканей или органов внутри одного и того же организма",
      "Пересадка искусственного органа"
    ],
    "answer": "Пересадка тканей или органов внутри одного и того же организма"
  },
  {
    "question": "Какой фактор важен для совместимости донора и реципиента?",
    "options": [
      "Группа крови",
      "Цвет глаз",
      "Рост",
      "Вес"
    ],
    "answer": "Группа крови"
  },
  {
    "question": "Какой тип трансплантации является наименее рискованным для реципиента?",
    "options": [
      "Аллотрансплантация",
      "Ксенотрансплантация",
      "Аутотрансплантация",
      "Изотрансплантация"
    ],
    "answer": "Аутотрансплантация"
  },

]
const quizDataHard = [

  {
    "question": "Как называется процесс проверки совместимости органа донора и реципиента?",
    "options": [
      "Гистосовместимость",
      "Антропометрическое исследование",
      "Биохимический анализ",
      "Генетическое тестирование"
    ],
    "answer": "Гистосовместимость"
  },
  {
    "question": "Какова основная цель применения иммуносупрессивной терапии после трансплантации?",
    "options": [
      "Улучшение функционирования трансплантата",
      "Предотвращение инфекций",
      "Подавление иммунной системы для предотвращения отторжения",
      "Стимуляция регенерации тканей"
    ],
    "answer": "Подавление иммунной системы для предотвращения отторжения"
  },
  {
    "question": "Какой вид трансплантации включает пересадку костного мозга?",
    "options": [
      "Эндотрансплантация",
      "Экзотрансплантация",
      "Гемопоэтическая стволовая клеточная трансплантация",
      "Нейротрансплантация"
    ],
    "answer": "Гемопоэтическая стволовая клеточная трансплантация"
  },
  {
    "question": "Какой механизм лежит в основе разработки толерогенных дендритных клеток, используемых для индукции оперативной толерантности к трансплантатам?",
    "options": [
      "Активация регуляторных Т-клеток",
      "Ингибирование проинфламматорных цитокинов",
      "Эпигенетическое перепрограммирование",
      "Индукция анергии в эффекторных Т-клетках"
    ],
    "answer": "Эпигенетическое перепрограммирование"
  },
  {
    "question": "В контексте трансплантации органов, какой подход к генной терапии исследуется для предотвращения хронического отторжения и продления выживаемости трансплантата, путем модификации экспрессии определенных иммунорегуляторных генов?",
    "options": [
      "Ингибирование экспрессии генов MHC",
      "Трансфекция генов антиоксидантных ферментов",
      "Индукция экспрессии генов толерогенных цитокинов",
      "Блокировка сигнальных путей апоптоза"
    ],
    "answer": "Индукция экспрессии генов толерогенных цитокинов"
  },
  {
    "question": "Какое явление в трансплантологии описывает случай, когда донорские иммунные клетки, пересаженные вместе с трансплантатом, вызывают иммунный ответ против тканей хозяина?",
    "options": [
      "Графт-против-хозяина болезнь (GVHD)",
      "Трансплантат-против-хозяина реакция (TVHR)",
      "Иммунный конфликт",
      "Хозяин-против-графта реакция (HVGR)"
    ],
    "answer": "Графт-против-хозяина болезнь (GVHD)"
  },
  {
    "question": "Какой термин описывает процесс управления экспрессией генов иммуносупрессивных агентов для оптимизации их эффективности и минимизации побочных эффектов у конкретного пациента?",
    "options": [
      "Фармакодинамика",
      "Фармакокинетика",
      "Фармакогенетика",
      "Фармакогеномика"
    ],
    "answer": "Фармакогеномика"
  },
  {
    "question": "Какой биомаркер используется для оценки риска развития хронического отторжения трансплантированной почки, основываясь на измерении уровней определенных молекул в моче?",
    "options": [
      "Протеинурия",
      "Непрямой коэффициент Доната",
      "Мочевые биомаркеры повреждения тубулярных клеток",
      "Уровень креатинина"
    ],
    "answer": "Мочевые биомаркеры повреждения тубулярных клеток"
  },
  {
    "question": "Какая передовая технология в области трансплантологии исследует использование микроРНК и малых интерферирующих РНК для модуляции патогенеза отторжения трансплантата на посттранскрипционном уровне?",
    "options": [
      "РНК-интерференция",
      "Геномное редактирование CRISPR-Cas9",
      "МикроРНК-таргетирование",
      "Транскриптомный анализ"
    ],
    "answer": "МикроРНК-таргетирование"
  },
  {
    "question": "Какой инновационный подход в трансплантологии включает использование тканеинженерных и биопринтинговых технологий для создания биосовместимых имплантатов и органов с целью устранения зависимости от донорских органов и уменьшения риска иммунного отторжения?",
    "options": [
      "Регенеративная медицина и биопринтинг",
      "Ксенотрансплантация",
      "Иммуномодулирующая терапия",
      "Использование синтетических органов"
    ],
    "answer": "Регенеративная медицина и биопринтинг"
  }

]

const shuffleArray = (
    //@ts-expect-error
    array) => {
  return array.sort(() => Math.random() - 0.5)
}


const quotes =[
  {
    "quote": "Искусство медицины состоит в том, чтобы занимать пациента, пока природа лечит болезнь.",
    "author": "Вольтер"
  },
  {
    "quote": "Медицина - это наука неопределенностей и искусство вероятности.",
    "author": "Уильям Ослер"
  },
  {
    "quote": "Наилучшее лекарство для доктора - это видеть, как его пациенты выздоравливают.",
    "author": "Гален"
  },
  {
    "quote": "Врач без сострадания больше похож на искусного мастера, чем на лечителя.",
    "author": "Иппократ"
  },
  {
    "quote": "Самое важное в медицине – это найти возможность быть человеком по отношению к своим пациентам.",
    "author": "Пол Фармер"
  },
  {
    "quote": "Лучшая медицина для души – это любовь к другим людям.",
    "author": "Парацельс"
  },
  {
    "quote": "Счастье – лучший доктор, а оптимизм – лучшее лекарство.",
    "author": "Джон Харви Келлог"
  },
  {
    "quote": "Медицина не только искусство, но и сердце. И в этом сердце есть много места для любви.",
    "author": "Карл Меннингер"
  },
  {
    "quote": "Мы не можем помочь всем, но каждый может помочь кому-то.",
    "author": "Рональд Рейган"
  },
  {
    "quote": "Врач видит человека во всей его слабости; но именно знание его слабости учит состраданию.",
    "author": "Альберт Швейцер"
  },
  {
    "quote": "Будьте осторожны, когда читаете книги о здоровье. У вас может развиться печатный гипохондрик.",
    "author": "Марк Твен"
  },
  {
    "quote": "Лекарства лечат болезни, но только врачи могут исцелять людей.",
    "author": "Карл Людвиг Бернхарди"
  },
  {
    "quote": "Хороший врач лечит не болезнь, а человека, который ею страдает.",
    "author": "Марк Твен"
  },
  {
    "quote": "Врач без знания языка человека не есть врач.",
    "author": "Авиценна"
  },
  // Другие цитаты...
]


function getRandomQuote() {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  const selectedQuote = quotes[randomIndex];
  return `${selectedQuote.quote}. (с) ${selectedQuote.author}`;
}

const App = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [selectedOption, setSelectedOption] = useState('')
  const [userAnswers, setUserAnswers] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [questions, setQuestions] = useState([])

  // useEffect(() => {
  //   // Перемешиваем вопросы при каждом новом запуске
  //   setQuestions(shuffleArray(quizData))
  // }, [])
  //

  const restartQuiz = () => {
    setIsDifficultyScreen(true);
    setCurrentQuestion(0)
    setSelectedOption('')
    setUserAnswers([])
    setShowResults(false)
  }


  const Results = ({data, correctAnswers, userAnswers}) => <Box display={'flex'} flexDirection={'column'} gap={4}>


    <Typography variant="h6" component="div">
      Результаты Викторины
    </Typography>
    {renderScoreAndLevel()}

    <Typography variant="body1" component="div">
      {`Правильных ответов: ${correctAnswers} из ${data.length}`}
    </Typography>
    {data.map((question, index) => (
        <Box display={'flex'} flexDirection={'column'} gap={1} key={index}>
          <Typography variant="body1" component="div">
            {`${index + 1}. ${question.question}`}
          </Typography>
          <Typography variant="body2" color={userAnswers[index] === question.answer ? 'lightgreen' : 'red'} component="div">
            {`Ваш ответ: ${userAnswers[index]}`}
          </Typography>
          <Typography variant="body2" component="div">
            {`Правильный ответ: ${question.answer}`}
          </Typography>
        </Box>
    ))}
  </Box>
  const renderResults = (difficulty) => {
    const correctAnswers = userAnswers.filter(
        (answer, index) => answer === questions[index]
            //@ts-expect-error
            .answer
    ).length
    switch (difficulty) {
      case 'easy': {
        return <Results data={quizDataEasy} correctAnswers={correctAnswers} userAnswers={userAnswers}/>
      }
      case 'medium': {
        return <Results data={quizDataMedium} correctAnswers={correctAnswers} userAnswers={userAnswers}/>
      }
      case 'hard': {
        return <Results data={quizDataHard} correctAnswers={correctAnswers} userAnswers={userAnswers}/>
      }
    }
  }
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);

  // Обновленный handleNextQuestion с логикой подсчета очков
  const handleNextQuestion = () => {
    // Проверка правильности ответа и начисление очков
    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 10); // Например, 10 очков за правильный ответ
    }

    // Обновление уровня в зависимости от очков
    if (score >= 80) { // Например, новый уровень каждые 50 очков
      setLevel(level + 1);
    }

    setUserAnswers([
      //@ts-expect-error
      ...userAnswers, selectedOption])
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
      setSelectedOption('')
    } else {
      setShowResults(true)
    }
  };

  const [difficulty, setDifficulty] = useState(localStorage.getItem('quizDifficulty') || 'easy');
  const [isDifficultyScreen, setIsDifficultyScreen] = useState(false);

  useEffect(() => {
    // Выбор набора вопросов в зависимости от сложности
    switch (difficulty) {
      case 'medium':
        setQuestions(shuffleArray(quizDataMedium));
        break;
      case 'hard':
        setQuestions(shuffleArray(quizDataHard));
        break;
      default:
        setQuestions(shuffleArray(quizDataEasy));
    }
  }, [difficulty]);
  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
    localStorage.setItem('quizDifficulty', newDifficulty);
    setIsDifficultyScreen(false);
  };

  console.log(isDifficultyScreen, 'isDiff')
  console.log(showResults, 'isResults')
  const renderDifficultyScreen = () => {
    const difficulties = ['easy', 'medium', 'hard'];

    return (
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Typography variant={'caption'}>{getRandomQuote()}</Typography>
          <Typography variant="h6" component="div">Выберите уровень сложности</Typography>
          <List>
            {difficulties.map((level) => (
                <ListItem
                    button
                    key={level}
                    onClick={() => handleDifficultyChange(level)}
                    disabled={level === 'medium' && score < 80 || level === 'hard' && score < 160}
                >
                  <ListItemText primary={level}/>
                </ListItem>
            ))}
          </List>
        </Box>
    );
  };
  const renderScoreAndLevel = () => {
    return (
        <div>
          <Typography variant="h6">Очки: {score}</Typography>
          <Typography variant={'caption'}>10 очков за каждый правильный ответ</Typography>
        </div>
    );
  };

  const Questions = ({data}) =>
      <>
        <Typography variant="h5" fontSize={18} component="div">
          {data[currentQuestion].question}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,


              }}
              name="quiz-options"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
          >
            {data[currentQuestion].options.map((option, index) => (
                <FormControlLabel
                    sx={{'label': {fontSize: 14}}}
                    key={index}
                    value={option}
                    control={<Radio/>}
                    label={option}
                />
            ))}
          </RadioGroup>
        </FormControl>
      </>
  const renderQuestionsByDifficulty = (difficulty) => {
    switch (difficulty) {
      case 'easy': {
        return <Questions data={quizDataEasy}/>
      }
      case 'medium': {
        return <Questions data={quizDataMedium}/>
      }
      case 'hard': {
        return <Questions data={quizDataHard}/>
      }
    }
  }


  return (

      <Box sx={{
        padding: '32px',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        gap: 4,
        height: '100%',
        justifyContent: 'space-between',
        boxSizing: 'border-box'
      }}>
        {isDifficultyScreen ? (
            renderDifficultyScreen()
        ) : showResults ? (
            renderResults(difficulty)
        ) : (
            <>
              {renderQuestionsByDifficulty(difficulty)}
            </>
        )}
        {showResults && (
            <Button variant="contained" onClick={restartQuiz}>
              К выбору сложности
            </Button>
        )}{' '}
        {isDifficultyScreen ? null : !showResults && (
            <Button
                sx={{height: '25%', alignItem: 'flex-end'}}
                variant="contained"
                onClick={handleNextQuestion}
                disabled={!selectedOption} // Кнопка неактивна, если нет выбранного варианта
            >
              {currentQuestion === questions.length - 1
                  ? 'Завершить викторину'
                  : 'Следующий вопрос'}
            </Button>
        )}
      </Box>

  )
}

export default App
